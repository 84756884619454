// src/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    username: '',
    managerloc: '',
    role: '',
    logged_in_info: '',
    has_completed_first_login: false,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const csrfToken = localStorage.getItem('csrf_token');
      const token = localStorage.getItem('jwttoken');

      try {
        const response = await axios.get('https://dev.itomtaal.nl/api/user', {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': csrfToken,
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        });
        const userData = response.data;
        setUserData({
          username: userData.username,
          managerloc: userData.managerloc,
          role: userData.role,
          logged_in_info: userData.logged_in_info,
          has_completed_first_login: userData.has_completed_first_login,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
};
