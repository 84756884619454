// src/components/ToastContext.js
import React, { createContext, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";

const CustomToastContainer = styled(ToastContainer)`
  .Toastify__toast-container {
    width: 300px;
    padding: 100px;
    margin: 20px; /* Position 20px above its default position */
  }
  .Toastify__toast--success {
    background: #d4edda; /* Light green background */
    color: #155724; /* Dark green text */
  }
  .Toastify__toast--error {
    background: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
  }
  .Toastify__toast--warning {
    background: #fff3cd; /* Light yellow background */
    color: #856404; /* Dark yellow text */
  }
  .Toastify__toast--info {
    background: #d1ecf1; /* Light blue background */
    color: #0c5460; /* Dark blue text */
  }
  .Toastify__toast-body {
    font-size: 14px;
    color: black;
  }
`;

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const addToast = (message, options) => {
    toast(message, options);
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <CustomToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ToastContext.Provider>
  );
};
