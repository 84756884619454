import React from 'react';

const BrowserOnly = ({ children }) => {
  if (typeof window !== 'undefined') {
    return children;
  }
  return null;
};

export default BrowserOnly;
