import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from './src/components/ToastContext';
import BrowserOnly from './browser-only';
import { UserProvider } from './src/UserContext';


export const wrapRootElement = ({ element }) => (
  <BrowserOnly>
  <UserProvider>
    <Router>
      <ToastProvider>
        {element}
      </ToastProvider>
    </Router>
    </UserProvider>
  </BrowserOnly>
);
